import { Component, Fragment } from 'react'
import { Col, Form } from 'antd'

import { ColWrapper, Divider } from 'App/components/Manage/FormItemMapper/Styled'

const FormItem = Form.Item

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
}

class InputMapper extends Component {
  renderDivider = label => {
    if (label) {
      return <Divider>{label}</Divider>
    } else {
      return null
    }
  }

  render() {
    const { fields, getFieldDecorator, headerTitle } = this.props

    return (
      <>
        <div style={{ paddingLeft: '15px', margin: '20px 0' }}>
          <b>{headerTitle}</b>
        </div>
        {fields?.map((field, fieldIndex) => {
          if (field.combine) {
            return (
              <FormItem key={[fieldIndex]} label={field.label} {...formItemLayout}>
                {field.cols.map((col, colIndex) => (
                  <FormItem key={[colIndex]}>
                    {getFieldDecorator(col.value, col.attributes)(col.input)}
                  </FormItem>
                ))}
              </FormItem>
            )
          }

          return (
            <Col key={[fieldIndex]}>
              {this.renderDivider(field.label)}
              <ColWrapper full={field.full} width={field.width}>
                {field.cols.map((col, colIndex) => {
                  if (col.isCustom) {
                    return (
                      <Fragment key={[colIndex]}>
                        {col.removeDecorator
                          ? col.input
                          : getFieldDecorator(col.value, col.attributes)(col.input)}
                      </Fragment>
                    )
                  } else {
                    return (
                      <FormItem key={[colIndex]} label={col.label} {...formItemLayout}>
                        {col.removeDecorator
                          ? col.input
                          : getFieldDecorator(col.value, col.attributes)(col.input)}
                      </FormItem>
                    )
                  }
                })}
              </ColWrapper>
            </Col>
          )
        })}
      </>
    )
  }
}

export default InputMapper
