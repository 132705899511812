import { useState } from 'react'
import * as Sentry from '@sentry/react'
import { Col, Icon } from 'antd'

import ErrorFallback from 'App/ErrorFallback'
import Companies from '../Companies'
import LeftFilter from '../LeftFilter'
import Logo from '../Logo'
import Options from '../Options'
import SearchBox from '../Search'
import { Content, Header } from '../Styled'
import styles from './StandardLayout.module.css'

function StandardLayout({ loggedInUser, children }) {
  const [showSideDrawer, setShowSideDrawer] = useState(false)

  return (
    <>
      <Header>
        <div className={styles.header}>
          <div className={styles.logo}>
            <Logo />
          </div>
          <div className={styles.companies} id="basecompany">
            <Companies />
          </div>
          <div className={styles.searchBox}>
            <SearchBox />
          </div>
          <div className={styles.options}>
            <Options loggedInUser={loggedInUser} />
          </div>
        </div>
      </Header>

      <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
        <Content>
          <Col span={showSideDrawer ? 3 : 1}>
            <Icon
              type="menu-fold"
              onClick={() => setShowSideDrawer(!showSideDrawer)}
              className={showSideDrawer ? styles.showIcon : styles.hideIcon}
            />
            <LeftFilter />
          </Col>
          <Col span={showSideDrawer ? 21 : 23}>{children}</Col>
        </Content>
      </Sentry.ErrorBoundary>
    </>
  )
}

export default StandardLayout
