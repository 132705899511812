import { format } from 'date-fns'
import { compact, identity, pick, pickBy, uniq } from 'lodash'

import type { JobType } from 'App/types/graphql'

type JobOrTrip = 'job' | 'trip'

export const filterDetails = (details: Record<string, any>, pickFields: string[]) =>
  pick(pickBy(details, identity), pickFields) || {}

export const getRequiredFieldKeys = (
  type: JobOrTrip,
  jobType: JobType,
  filterFnc: (field) => boolean = f => !!identity(f)
) => {
  let requiredFields = jobType?.requiredFields || []
  let dynamicFields =
    type === 'job' ? jobType?.dynamicFields || [] : jobType?.tripDynamicFields || []

  requiredFields = requiredFields.filter(filterFnc)
  dynamicFields = dynamicFields.filter(filterFnc)

  return compact(uniq([...requiredFields.map(f => f?.name), ...dynamicFields.map(f => f?.key)]))
}

export const getTrimmedDetails = (
  type: JobOrTrip,
  details: Record<string, any>,
  jobType?: JobType | null
) => {
  const rawDetails = { ...(details || {}) }

  if (!jobType) return rawDetails

  const detailsFields = getRequiredFieldKeys('job', jobType)

  return filterDetails(rawDetails, detailsFields)
}

export const getTrimmedInput = (job: any, jobTypes: JobType[], values?: any, type?: any) => {
  const isValidDate = (dateString: string): boolean => {
    return !isNaN(new Date(dateString).getTime())
  }

  const formattedValues = Object.keys(values).map(key => {
    return {
      [key]: format(values[key], 'DD/MM/YYYY HH:mm')
    }
  })
  const newValues = Object.assign({}, ...formattedValues)
  const rawDetails = { ...(newValues || {}) }
  const foundJobType = jobTypes.find(jt => jt.code === job.job.type)
  if (!foundJobType) return rawDetails

  const detailsFields = [
    ...uniq(foundJobType?.requiredFields?.map(k => k?.name) as string[]),
    ...(type === 'job'
      ? (foundJobType?.dynamicFields?.filter(f => f?.type === 'DATE').map(m => m?.key) as string[])
      : (foundJobType?.tripDynamicFields?.map(k => k?.key) as string[]))
  ].filter(Boolean)

  const trimmedDetails = filterDetails(rawDetails, detailsFields)

  const filteredDetails = Object.fromEntries(
    Object.entries(trimmedDetails).filter(([key, value]) => isValidDate(value))
  )

  return filteredDetails
}
