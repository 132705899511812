import { memo } from 'react'
import { Icon, Result, Typography } from 'antd'

const NoAccess = () => {
  return (
    <Result
      icon={<Icon type="lock" theme="outlined" style={{ color: '#F0F0F0' }} />}
      extra={
        <Typography.Text style={{ color: 'lightgray', fontSize: '1rem' }}>
          No Access
        </Typography.Text>
      }
    />
  )
}

export default memo(NoAccess)
