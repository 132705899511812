export const stringifyCellValue = (value: any) => {
  if (Array.isArray(value) || typeof value === 'object' && value) {
    return JSON.stringify(value);
  }

  return value || '-';
}

export const parseCellValue = (value: any) => {
  return JSON.parse(value);
}

export const getDisplayCellValue = (value: any) => {
  if (Array.isArray(value) && value.every(v => typeof v === 'string')) {
    return value.join(', ');
  } else if (typeof value === 'object' && value) {
    return JSON.stringify(value);
  }

  return value || '-';
}