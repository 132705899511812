import { compose } from 'react-apollo'
import { RedoOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import PropTypes from 'prop-types'
import { withHandlers, withState } from 'recompose'

import { StyledButton } from '../../ButtonWrapper'
import RolloverModal from './RolloverModal'

const state = withState('isModalVisible', 'setIsModalVisible', false)

const handlers = withHandlers({
  onActionClick: props => event => {
    props.setIsModalVisible(true)
  },
  onModalClose: props => event => {
    props.setIsModalVisible(false)
  }
})

const enhance = compose(state, handlers)

const RolloverAction = props => {
  return (
    <>
      <Tooltip title="Rollover">
        <StyledButton style={{ marginLeft: '10px' }} onClick={props.onActionClick}>
          {/* Rollover */}
          <RedoOutlined />
        </StyledButton>
      </Tooltip>
      {props.isModalVisible && <RolloverModal job={props.job} onClose={props.onModalClose} />}
    </>
  )
}

RolloverAction.propTypes = {
  isModalVisible: PropTypes.bool,
  onActionClick: PropTypes.func,
  onModalClose: PropTypes.func,

  job: PropTypes.object
}

export default enhance(RolloverAction)
