import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ApolloClient, useMutation } from '@apollo/client'
import { Button, Col, DatePicker, Divider, Form, notification, Popover, Timeline } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import moment from 'moment'

import { getTrimmedInput } from 'App/components/Booking/NewBookingForm/SubmitAction/helper'
import { IconButton } from 'App/components/Workflow/Chronology/Styled'
import { UPDATE_JOB, UPDATE_JOB_TRIP } from 'App/graphql/booking'
import { Job, JobType } from 'App/types/graphql'
import { logger } from 'App/utils/logger'
import { ChronoDate, ChronoName, Wrapper } from './Styled'

interface RenderJobSchedulesProps extends FormComponentProps {
  jobs: Job[]
  refetch: () => void
  client: ApolloClient<any>
  jobTypes: JobType[]
}

const RenderJobSchedules = ({ jobs, refetch, client, jobTypes }: RenderJobSchedulesProps) => {
  const { t } = useTranslation()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [formModalVisible, setFormModalVisible] = useState<boolean>(false)
  const [updateJobMutation] = useMutation(UPDATE_JOB, { client })
  const [updateJobTripMutation] = useMutation(UPDATE_JOB_TRIP, { client })

  const WrappedForm = Form.create()(props => {
    const { form } = props
    const { getFieldDecorator, validateFields } = form

    if (!jobs) {
      return null
    }

    const isValidDate = (dateString: string) => {
      const dateRegex = /^\d{2}\/\d{2}\/\d{4} \d{2}:\d{2}$/
      return dateRegex.test(dateString) && !isNaN(new Date(dateString).getTime())
    }
    const sortDate = (details: { [key: string]: string }): { [key: string]: string } => {
      const sortedEntries = Object.entries(details)
        .filter(([key, value]) => isValidDate(value))
        .sort(([, a], [, b]) => new Date(a).getTime() - new Date(b).getTime())

      const sortedObject: { [key: string]: string } = {}
      sortedEntries.forEach(([key, value]) => {
        sortedObject[key] = value
      })

      return sortedObject
    }
    const filterJobDetails = jobs => {
      return jobs
        .map(job => ({
          ...job,
          details: sortDate(job.details),
          trip: job.trip.map(trip => ({
            ...trip,
            details: sortDate(trip.details)
          }))
        }))
        .sort((b, a) => a.jobNo.localeCompare(b.jobNo))
    }

    const filteredJobs = filterJobDetails(jobs)
    const editForm = (job: any, value: any, key: string, type: string) => {
      const dateFormat = 'DD/MM/YYYY HH:mm a'
      const date = moment(value, dateFormat)
      const formItemLayout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 }
      }

      const rules = [
        {
          required: true,
          message: t('validation.inputValue')
        }
      ]

      const handleFormSubmit = async e => {
        e.preventDefault()
        ;(validateFields as Function)(async (err: any, values: any) => {
          const mutation = type === 'job' ? updateJobMutation : updateJobTripMutation
          const input: any = {}
          if (type === 'job') {
            ;(input.uuid = job.job.uuid),
              (input.details = getTrimmedInput(job, jobTypes, values, type))
          } else if (type === 'trip') {
            ;(input.jobUuid = job.job.uuid),
              (input.trips = {
                uuid: job.trip[0].uuid,
                details: getTrimmedInput(job, jobTypes, values, type)
              })
            input.type = job.job.type
          }

          if (!err) {
            try {
              await mutation({ variables: { input } })
              notification.success({
                message: `${t('validation.submitNotice')}`,
                description: `${t('validation.successfullyUpdateJob')}`
              })
              setFormModalVisible(false)

              refetch()
            } catch (error) {
              logger.error('Update Job Error', error)
              return notification.error({
                message: 'Error',
                description: (error as Error).message.replace('GraphQL error: ', '')
              })
            }
          }
        })
      }

      return (
        <Form style={{ width: '500px', height: '95px' }}>
          <Form.Item label="Date & Time:" {...formItemLayout}>
            {(getFieldDecorator as Function)(`${key}`, {
              rules,
              initialValue: date
            })(
              <DatePicker
                showTime
                placeholder={`${t('validation.selectDateTime')}`}
                format={dateFormat}
              />
            )}
          </Form.Item>
          <Divider style={{ margin: '12px 0' }} />
          <Col span={18} style={{ textAlign: 'right' }}>
            <Button onClick={() => setFormModalVisible(false)}>Cancel</Button>
            <Button type="primary" onClick={e => handleFormSubmit(e)} style={{ marginLeft: '5px' }}>
              {t('common.update')}
            </Button>
          </Col>
        </Form>
      )
    }

    return (
      <Wrapper>
        {filteredJobs.length && Object.keys(filteredJobs[0]?.details).length > 0
          ? filteredJobs?.map((job: any, index: number) => (
              <Timeline.Item key={job.jobNo}>
                <ChronoName>{job.jobNo}</ChronoName>
                <div style={{ display: 'flex', gap: '15rem' }}>
                  <ChronoDate>
                    {Object.entries(job.details).map(([key, value]) => (
                      <div style={{ marginBottom: '30px' }} key={key}>
                        <ChronoName>{key}</ChronoName>
                        <span style={{ display: 'flex' }}>
                          <ChronoDate>{value}</ChronoDate>
                          <Popover
                            content={editForm(job, value, key, 'job')}
                            placement="right"
                            title={`Job ${index + 1}: ${key}`}
                            trigger="click"
                          >
                            <IconButton icon="edit" onClick={() => setFormModalVisible(true)} />
                          </Popover>
                        </span>
                      </div>
                    ))}
                  </ChronoDate>
                  <ChronoDate>
                    {job?.trip?.map(tripItem => (
                      <div key={tripItem} style={{ marginBottom: '30px' }}>
                        <ChronoName>Trip {tripItem.sequence}</ChronoName>
                        {Object.entries(tripItem.details).map(([key, value]) => (
                          <div style={{ marginBottom: '10px' }} key={key}>
                            <ChronoName>{key}</ChronoName>
                            <span style={{ display: 'flex' }}>
                              <ChronoDate>{value}</ChronoDate>
                              <Popover
                                content={editForm(job, value, key, 'trip')}
                                placement="right"
                                title={`Trip ${tripItem.sequence}: ${key}`}
                                trigger="click"
                              >
                                <IconButton icon="edit" onClick={() => setFormModalVisible(true)} />
                              </Popover>
                            </span>
                          </div>
                        ))}
                      </div>
                    ))}
                  </ChronoDate>
                </div>
              </Timeline.Item>
            ))
          : null}
      </Wrapper>
    )
  })

  return <WrappedForm />
}
export default RenderJobSchedules
