import { getActTime } from "@/components/RelativeDateTime"
import { tableRowParser } from "@/components/Transport/Components/Monitoring/JobStatus/getTableAccessors"
import { TransportReportJob } from "@/types/graphql"
import moment from "moment"

type FncOpts = Record<string, any>

export const columnFunctions = {
  dateFormatter: {
    f: (value: any, opts: FncOpts, record: TransportReportJob) => {
      return opts.format ? moment(value).format(opts.format) : getActTime(value) //default format if not provided
    },
    eg: '{ format: "DD/MM/YYYY" } => 01/01/2021'
  },
  dateDifference: {
    f: (value: any, opts: FncOpts, record: TransportReportJob) => {
      if (!opts.startDate || !opts.endDate) return null

      const startDate = moment(tableRowParser(record, opts.startDate))
      const endDate = moment(tableRowParser(record, opts.endDate))
      const diff = endDate.diff(startDate, opts.unit || 'days')

      return diff
    },
    eg: '{ startDate: "bookingDetails.shipperRequiredDate", endDate: "bookingDetails.consigneeRequiredDate", unit: "days" } => 5'
  }
}

