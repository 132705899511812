import { gql } from '@apollo/client'
import find from 'lodash/find'
import includes from 'lodash/includes'
import isNumber from 'lodash/isNumber'
import numeral from 'numeral'
import uuidv4 from 'uuid/v4'

export const statuses = [
  'DRAFT',
  'SUBMITTED',
  'APPROVED',
  'PARTIALLY_PAID',
  'PAID',
  'DELETED',
  'VOIDED'
]

export const types = [
  'expCompOutstandingSupplierPaymentVoucher',
  'expCompOutstandingInvoiceVoucher',
  'expCompErrosAndOmissionsVoucher',
  'expCompSupplierWarehousePayment',
  'expCustoms',
  'expCustShipperCustomsPaymentInvoice',
  'expCustCustomsTaxPaymentVoucher',
  'expLiner',
  'expLinerLinerPayment',
  'expPortBill',
  'impCompErrosAndOmissionsVoucher',
  'impCompSupplierWarehousePayment',
  'impCompOutstandingSupplierPaymentVoucher',
  'impCompOutstandingConsigneeInvoiceVoucher',
  'impCustCustomsDutyVoucher',
  'impCustConsigneeCustomsPaymentInvoiceVoucher',
  'impCustCustomsTaxPaymentVoucher',
  'impLinerLinerPayment',
  'impPortBill'
]

export const getTax = (chargeItem, company, isAp, exemptedTax) => {
  const filters = [
    {
      tag: 'isInterBranch',
      ap: 'inputInternalBranch',
      ar: 'outputInternalBranch'
    },
    {
      tag: 'isInterComp',
      ap: 'inputInternal',
      ar: 'outputInternal'
    }
  ]

  const filter =
    company &&
    company.tags &&
    find(filters, filter => {
      return includes(company.tags, filter.tag)
    })

  if (filter) {
    const ciTax = find(chargeItem.taxes, tax => (tax.type === isAp ? filter.ap : filter.ar))

    return {
      uuid: ciTax.tax.uuid,
      code: ciTax.tax.code,
      name: ciTax.tax.name,
      percentage: isNumber(ciTax.rate) ? ciTax.rate : ciTax.tax.rate
    }
  } else {
    return isAp ? chargeItem.costTax : exemptedTax?.data?.tax || chargeItem.sellTax
  }
}

const TAX_QUERY = gql`
  query Tax($uuid: UUID!) {
    tax(uuid: $uuid) {
      code
      isActive
      name
      percentage
      uuid
      exemptionDocumentUuid
    }
  }
`
const CHARGE_ITEM_MISCS_QUERY = gql`
  query ChargeItemMiscs($customerUuid: UUID, $chargeItemUuid: UUID) {
    chargeItemMiscs(customerUuid: $customerUuid, chargeItemUuid: $chargeItemUuid) {
      rows {
        documentUuid
        type
        startDate
        endDate
        value
        uuid
      }
    }
  }
`

export const recalibrateVoucherItem = async (
  costItem,
  isAp,
  bookingUuid,
  job,
  selectedCompany,
  overrideExchangeRate,
  client,
  selectedVoucher
) => {
  const quantity = costItem.quantity

  const { data } = await client.query({
    query: CHARGE_ITEM_MISCS_QUERY,
    variables: {
      type: 'tax',
      chargeItemUuid: costItem.chargeItem.uuid,
      customerUuid: selectedVoucher.customer?.uuid || selectedVoucher.vendor?.uuid
    }
  })

  const taxUuid = data?.chargeItemMiscs?.rows?.[0]?.value

  const selectTaxUuid =
    taxUuid ||
    costItem.taxUuid ||
    (isAp ? costItem.chargeItem.costTax.uuid : costItem.chargeItem.sellTax.uuid)
  const currency = isAp ? costItem.costCurrency : costItem.sellCurrency

  const exemptedTax =
    taxUuid && (await client.query({ query: TAX_QUERY, variables: { uuid: taxUuid } }))
  const tax = getTax(costItem.chargeItem, selectedCompany, isAp, exemptedTax)

  const exchangeRate =
    overrideExchangeRate || (isAp ? costItem.costExchangeRate : costItem.sellExchangeRate)
  const taxPercentage = costItem.taxPercentage || tax.percentage
  const baseRate = isAp ? costItem.costBaseRate : costItem.sellBaseRate
  const rate = numeral(baseRate)
    .multiply(exchangeRate || 1)
    .value()
  const taxTotal = numeral(rate).multiply(taxPercentage).divide(100).multiply(quantity).value()
  const subTotal = numeral(rate).multiply(quantity).value()
  const total = numeral(subTotal).add(taxTotal).value()

  return {
    uuid: uuidv4(),
    unit: costItem.unit,
    size: costItem.size,
    rate,
    total,
    isDeleted: costItem.isDeleted,
    subTotal,
    currency,
    tax,
    taxUuid: selectTaxUuid,
    accrual: costItem.accrual,
    baseRate,
    taxTotal,
    costItem,
    sellRate: costItem.sellRate,
    costRate: costItem.costRate,
    quantity: costItem.quantity,
    bookingUuid,
    job,
    jobUuid: job?.uuid,
    exchangeRate,
    taxPercentage
  }
}

export const calculateUnbilled = (isAp, costItem) => {
  const rate = isAp ? costItem.costRate : costItem.sellRate
  const account = isAp ? costItem.accountPayable : costItem.accountReceivable
  const accountDraft = isAp ? costItem.accountPayableDraft : costItem.accountReceivableDraft
  const unbilled = rate * costItem.quantity - (account + accountDraft)

  return unbilled
}

export const calculateGrossProfit = costItem => {
  const totalAp = costItem.costRate * costItem.quantity
  const totalAr = costItem.sellRate * costItem.quantity

  const grossProfit = totalAr - totalAp

  return grossProfit
}

export const round2Digits = value => {
  if (!value) {
    return 0
  }
  const stringValue = value.toString()
  return Number(Math.round(parseFloat(`${stringValue}e2`)) + 'e-2')
}

export const formatCurrencyAmount = (amount, countryAlpha2 = 'MY', currency = 'MYR') => {
  const roundedAmount = amount && round2Digits(amount)
  return `${
    new Intl.NumberFormat(`en-${countryAlpha2}`, { style: 'currency', currency }).format(
      roundedAmount || 0
    ) || (0.0).toFixed(2)
  }`
}

export const sortVoucherItems = voucherItems => {
  return voucherItems.sort((a, b) => {
    if (a.sorting === null) return -1
    if (b.sorting === null) return 1
    return a.sorting - b.sorting
  })
}
