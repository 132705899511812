import { uniq } from 'lodash'
import pick from 'lodash/pick'
import moment, { Moment } from 'moment'
import { v4 as uuidV4 } from 'uuid'

import { rootKeys } from 'App/components/Booking/DynamicField/helper'
import { getTrimmedDetails } from 'App/components/Booking/NewBookingForm/SubmitAction/helper'
import type { AddJobInput, DynamicField, JobType, MakeBookingInput } from 'App/types/graphql'
import { DynamicFieldQuery } from 'App/types/graphql'

const getLastTrip = (jobs: AddJobInput[]) => {
  const lastJob = jobs[jobs.length - 1]
  return lastJob?.trips?.[lastJob?.trips.length - 1]
}

const chronologyDateKeys = ['shipperRequiredDate', 'consigneeRequiredDate']

export const getChronologyDate = (type: any, key: string, value: any = moment()): Moment => {
  if (type === 'TRANSPORT' && chronologyDateKeys.includes(key)) {
    return value?.set({ hour: 14, minute: 0, second: 0, millisecond: 0 })
  }

  return value
}

export const parseDetails = (details: any, dynamicFields: DynamicField[]) => {
  const detailsItemsToParse = uniq(
    dynamicFields.reduce((items, df) => {
      if (df.query === DynamicFieldQuery.Company) {
        df.key && items.push(df.key)
      }

      return items
    }, [] as any[])
  )

  detailsItemsToParse.forEach(item => {
    if (item) {
      try {
        details[item] = JSON.parse(details[item]).uuid
      } catch (e) {
        // do nothing
      }
    }
  })
}

export default (values: any, jobTypes: JobType[] = []): Partial<MakeBookingInput> | undefined => {
  const input: Partial<MakeBookingInput> = pick(values, [
    'uuid',
    'type',
    'override',
    'isOverrideBlockDuplicate',
    'tags',
    'shipperUuid',
    'shipperAddressUuid',
    'consigneeUuid',
    'consigneeAddressUuid',
    'billToUuid',
    'jobs',
    'details',
    'forwarderDest',
    'forwarderOri',
    ...rootKeys
  ])

  let hasInvalidPoint = false
  for (let i = 0; i < values.jobs.length; i++) {
    const invalidPoint = values.jobs[i].trips?.find(
      (t: any) => !t.toCompanyUuid || !t.toUuid || !t.fromCompanyUuid || !t.fromUuid
    )

    if (invalidPoint) {
      hasInvalidPoint = true
      throw new Error(`Please select the company and address for all trips in Job #${i + 1}.`)
    }
  }

  if (hasInvalidPoint) {
    return
  }

  input.details.shipperUuid = values.jobs[0].trips[0]?.fromCompanyUuid
  input.details.shipperAddressUuid = values.jobs[0].trips[0]?.fromUuid
  const lastTrip = getLastTrip(values.jobs)
  input.details.consigneeUuid = lastTrip?.toCompanyUuid
  input.details.consigneeAddressUuid = lastTrip?.toUuid

  input.jobs = [
    ...values.jobs.map((job: AddJobInput) => {
      const jt = jobTypes.find(j => j.code === job.type)
      const formattedJob = {
        details: getTrimmedDetails('job', job.details, jt),
        type: job.type,
        no: job.no,
        remarks: job.remarks,
        trips: job.trips?.map(t => {
          //@ts-ignore
          const { key, ...rest } = t
          rest.uuid = rest.uuid || uuidV4()
          rest.details = getTrimmedDetails('trip', rest.details, jt)
          return rest
        }),
        tripFormat: job.tripFormat,
        uuid: uuidV4()
      }

      return formattedJob
    })
  ]

  return input
}
