import { gql } from '@/types'

export const QUERY_JOB = gql(`
  query jobContainerSeal($uuid: UUID!) {
    job(uuid: $uuid) {
      type
      uuid
      no
      details
      trips {
        uuid
        details
      }
    }
  }
`)

export const UPDATE_TRIP = gql(`
  mutation updateTrip($input: UpdateTripInput) {
    updateTrip(input: $input) {
      success
      message
    }
  }
`)

export const UPDATE_JOB = gql(`
  mutation updateJob($input: UpdateJobInput) {
    updateJob(input: $input) {
      uuid
    }
  }
`)
