import { startCase } from 'lodash'

interface DisplayBookingDetailsProps {
  leg: any
  headerTitle: string
}

const DisplayBookingDetails = ({ leg, headerTitle }: DisplayBookingDetailsProps) => {
  const displayBookingDetails = Object.keys(leg?.bookingDetails || {}).map(key => {
    return (
      <div key={key} style={{ display: 'flex', gap: 5 }}>
        <div
          style={{
            width: 145,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            fontWeight: '500',
            color: 'rgb(130, 130, 130)',
            letterSpacing: '0.2px'
          }}
        >
          {startCase(key)}
        </div>
        :<div style={{ marginLeft: 10 }}>{leg?.bookingDetails[key]}</div>
      </div>
    )
  })
  return (
    <>
      <div style={{ margin: '20px 0' }}>
        <b>{headerTitle}</b>
      </div>
      {displayBookingDetails}
    </>
  )
}
export default DisplayBookingDetails
